import React, {useState, useEffect} from 'react';
import { Parallax } from "react-parallax";
import { Carousel } from 'react-responsive-carousel';
import Valores from './valores';
import Slide from 'react-reveal/Slide';
import Zoom from 'react-reveal/Zoom';
import ScrollspyNav from "react-scrollspy-nav";
import SVG from 'react-inlinesvg';
import { Lines } from 'react-preloaders';
import Fade from 'react-reveal/Fade';
import { Modal } from "react-responsive-modal";
import CarouselMain from './carousel-main';
import './App.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-responsive-modal/styles.css";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center"
};
const insideStyles = {
  background: "white",
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)"
};

const insideStyles2 = {
  padding: 20,
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
  fontSize: "3em"
};

const menuStyle = {
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
  },
  sidebar: {
    zIndex: 2,
    position: "fixed",
    top: 0,
    bottom: 0,
    transition: "transform .3s ease-out",
    WebkitTransition: "-webkit-transform .3s ease-out",
    willChange: "transform",
    overflowY: "auto",
    background: "white",
    width: "300px",
    color: "black"
  },
  content: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflowY: "auto",
    WebkitOverflowScrolling: "touch",
    transition: "left .3s ease-out, right .3s ease-out"
  },
  overlay: {
    zIndex: 1,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    opacity: 0,
    visibility: "hidden",
    transition: "opacity .3s ease-out, visibility .3s ease-out",
    backgroundColor: "rgba(0,0,0,.3)"
  },
  dragHandle: {
    zIndex: 1,
    position: "fixed",
    top: 0,
    bottom: 0
  }
};

const ColoredLine = ({ color }) => (
  <hr
      style={{
          color: color,
          backgroundColor: color,
          height: 1,
          width: "100%",
          margin: 0,
          padding: 0
      }}
  />
);



function App(props) {
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [charge, setCharge] = useState(false)

  useEffect(() => {
    setTimeout(function () {
      setCharge(true);
    }, 1600);
  }, [])

  function send(){
    console.log({name, email, phone, message});

  }

  return (
    <div className="App">
      <div className="nav">
        <ScrollspyNav
          scrollTargetIds={["section1", "section2", "section3", "section4", "section5"]}
          activeNavClass="is-active"
          className="nav-sect"
        >
          <a href="#section1">
            <div className="item-nav">
              <i className="material-icons">apartment</i>
              <p>¿Quiénes Somos?</p>
            </div>
          </a>
          <a href="#section2">
            <div className="item-nav" href="#section2">
              <i className="material-icons">flag</i>
              <p >Misión y Visión</p>
            </div>
          </a>
          <a href="#section3">
            <div className="item-nav" href="#section3">
              <i className="material-icons">layers</i>
              <p>Servicios</p>
            </div>
          </a>
          <a href="#section4">
            <div className="item-nav" href="#section4">
              <i className="material-icons">rowing</i>
              <p>Proyectos</p>
            </div>
          </a>
          <a href="#section5">
            <div className="item-nav" href="#section5">
              <i className="material-icons">email</i>
              <p>Contacto</p>
            </div>
          </a>
        </ScrollspyNav>
      </div>
      <div className="app-content">
        <Slide left cascade duration={1000} delay={1500}>
          <CarouselMain />
          <div className="main-row">
            <div id="main-info">
              <img id="main-img" src={process.env.PUBLIC_URL + '/new-logo.png'}/>
            </div>
          </div>
        </Slide>
        <Slide right duration={1000} delay={1750}>
          <div className="info-section">
            <h2 className="titles" id="section1">
            <Zoom delay={1750} duration={800}>
              ¿Quiénes Somos?
            </Zoom>
            </h2>
            <Fade button cascade delay={3200}>
              <div className="who">
                <div id="img-who">
                  <SVG src={process.env.PUBLIC_URL + '/iconos/quienessomos.svg'} width="auto" />
                </div>
                <div id="text-who">
                  <span>
                    Somos una empresa dedicada a proveer amplia respuesta experta a cualquier necesidad de trabajos 
                    en construcción y topografía con la calidad y calidez necesaria para competir en el mercado actual. 
                    <br/>DEIGEO es una empresa naciente, con la visión de convertirse en líder en el campo de la construcción 
                    contando con amplia experiencia, reconocimiento, confianza y la satisfacción de nuestros clientes.
                  </span>
                </div>
              </div>
            </Fade>
          </div>
        </Slide>
        <Slide left cascade duration={2000}>
          <Parallax bgImage={process.env.PUBLIC_URL + '/cover-2.jpg'} strength={500}>
            <div id="mantra-div">
              <div id="mantra">
                <span>DESARROLLO EN INGENIERÍA<br /> GEOMÁTICA, <br />ESTRUCTURAS Y OBRAS.</span>
              </div>
            </div>
          </Parallax>
        </Slide>
        <Slide right duration={2000}>
          <div className="section-row misvis-mob" id="section2">
            <div id="mision">
              <SVG src={process.env.PUBLIC_URL + '/iconos/mision.svg'} height="150px" width="auto" />
              <h2 className="titles">Misión</h2>
              <ul className="list-mision">
                <li>
                  <span>
                    Crear la pronta y óptima solución para las necesidades constructivas de cada cliente en particular, 
                    brindándoles la mejor calidad, respuesta, cooperación, ética profesional e innovación del mercado. 
                  </span>
                </li>
                <li>
                  <span>
                    Convertirnos en la constructora mexicana de confianza, 
                    plasmando cada obra con la calidad y profesionalismo que deseamos sean la firma de la empresa.
                  </span>
                </li>
                <li>
                  <span>
                    Apoyar y servir a nuestra patria generando empleo con sueldos competitivos y prestaciones, 
                    apoyando de esta manera a las familias mexicanas a crecer y a su vez, nosotros crecer de la mano con ellas.
                  </span>
                </li>
                <li>
                  <span>
                    Atender cada obra con responsabilidad y profesionalismo, 
                    salvaguardadando la seguridad y patrimonio de cada uno de nuestros clientes y trabajadores.
                  </span>
                </li>
              </ul>
            </div>
            <div id="vision">
              <SVG src={process.env.PUBLIC_URL + '/iconos/vision.svg'} height="150px" width="auto" />
              <h2 className="titles">Visión</h2>
              <p>
                Consolidarnos como una empresa líder en servicios de construcción y topografía a través 
                de la confianza, innovación, y adaptación para lograr la total satisfacción de nuestros clientes. 
                Ser transparentes y responsables en cada uno de los trabajos a realizar.
              </p>
            </div>
          </div>
        </Slide>
        <Slide left cascade duration={2000}>
          <Parallax bgImage={process.env.PUBLIC_URL + '/cover-3.jpg'} strength={500}>
            <div style={{ height: 500 }}>
              <div style={insideStyles2} className="titles"><Valores /></div>
            </div>
          </Parallax>
        </Slide>
        <Slide right duration={2000}>
          <div className="section-column" id="section3">
            <h1 className="titles">
              <Zoom mirror={true} duration={800} delay={100}>Servicios</Zoom>
            </h1>
            <div className="services">
              <div className="service">
                <Fade buttom delay={2000}>
                  <h2 className="titles">Construcción</h2>
                  <SVG src={process.env.PUBLIC_URL + '/iconos/construccion2.svg'} width="250px" height="250px" />
                  <span>
                    Contamos con profesionales experimentados en el sector constructivo y tenemos la capacidad 
                    operativa necesaria para la realización de cualquier obra civil, 
                    con el compromiso corporativo de ofrecer la mejor experiencia preliminar, durante y al finalizar la obra, 
                    con la mejor garantía de satisfacción.
                  </span>
                </Fade>
              </div>
              <div className="service">
                <Fade buttom delay={2000}>
                  <h2 className="titles">
                    Proyecto ejecutivo
                  </h2>
                  <SVG src={process.env.PUBLIC_URL + '/iconos/proyectoejecutivo.svg'} width="250px" height="250px" />
                  <span>
                    Elaboración de planos estructurales y arquitectónicos, Ingeniería de detalle para el diseño y 
                    especificaciones de terracerías, pisos de concreto, demolición y retiro de material, cimentación y 
                    armado de estructuras, instalaciones hidráulicas, ingeniería de costos, administración de obra y 
                    supervisión de la misma.
                  </span>
                </Fade>
              </div>
              <div className="service">
                <Fade buttom delay={2000}>
                  <h2 className="titles">Topografía</h2>
                  <SVG src={process.env.PUBLIC_URL + '/iconos/topografia2.svg'} width="250px" height="250px" />
                  <span>
                    Configuración de terreno natural por medio de estación total, obtención de volúmenes de corte y terraplén, 
                    control de obra vertical y horizontal, deslindes, lotificaciones, levantamientos de carreteras, ríos, 
                    barrancos, líneas costeras, líneas de alta, media y baja tensión, levantamientos topo-arquitectónicos, 
                    trabajos de nivelación, control de hundimientos y desplomes, diseño, trazo y construcción de vías terrestres.
                  </span>
                </Fade>
              </div>
              <div className="service">
                <Fade buttom delay={2000}>
                  <h2 className="titles" id="remod">Remodelación y mantenimiento</h2>
                  <SVG src={process.env.PUBLIC_URL + '/iconos/remodelacionymantenimiento2.svg'} width="250px" height="250px" />
                  <span>
                    Prestamos servicio de remodelación y mantenimiento a edificios, casas, oficinas, espacios deportivos 
                    y edificaciones públicas y privadas, impermeabilización, drenaje e instalaciones hidráulicas y eléctricas.
                  </span>
                </Fade>
              </div>
              <div className="service">
                <Fade buttom delay={2000}>
                  <h2 className="titles">Geomática</h2>
                  <SVG src="https://image.flaticon.com/icons/svg/96/96615.svg" width="250px" height="250px" />
                  <span>
                    Configuración de terreno natural por medio de GPS (RTK) y dron, obtención y procesamiento de ortofotografías, 
                    modelos digitales de terreno y de elevación (MDT y MDE). Obtención y procesamiento de imágenes satelitales, 
                    percepción remota, batimetrías, líneas de control GPS y puntos de control para vuelos de dron o LiDAR, 
                    escáner topográfico y Sistemas de Información Geográfica (SIG).
                  </span>
                </Fade>
              </div>
            </div>
          </div>
        </Slide>
        <Slide left cascade duration={2000}>
          <Parallax bgImage={process.env.PUBLIC_URL + '/cover-4.JPG'} strength={500}>
            <div style={{ height: 500 }}>
            </div>
          </Parallax>
        </Slide>
        <Slide right duration={2000}>
          <div className="section-column" id="section4">
            <h2 className="titles">
              <Zoom mirror={true} duration={800} delay={500}>Proyectos</Zoom>
            </h2>
            <div id="carousel-sections">
              <Fade buttom delay={3500}>
                <div className="carousel-sect">
                  <h2 className="titles">Torre BBVA</h2>
                  <Carousel showArrows={true}>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/bbva/bbva-1.JPG'} />
                        <p className="legend">Trabajos de Armado y habilitado de Acero. Cimentación superficial y estructura.</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/bbva/bbva-2.JPG'} />
                        <p className="legend">Supervisión: COREY</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/bbva/bbva-3.JPG'} />
                        <p className="legend">Personal a cargo: 120 trabajadores</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/bbva/bbva-4.jpg'} />
                        <p className="legend">Ubicación: Av. Paseo de la Reforma, Juárez, CDMX.</p>
                    </div>
                  </Carousel>
                </div>
              </Fade>
              <Fade buttom delay={4000}>
                <div className="carousel-sect">
                  <h2 className="titles">Centro comercial Encuentro-Fortuna</h2>
                  <Carousel showArrows={true}>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/encuentro-fortuna/enc-for-1.jpg'} />
                        <p className="legend">Supervisión: Pulso inmobiliario</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/encuentro-fortuna/enc-for-2.jpg'} />
                        <p className="legend">Personal a cargo: 185 trabajadores</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/encuentro-fortuna/enc-for-3.JPG'} />
                        <p className="legend">Ubicación: Av. Fortuna, Lindavista, CDMX</p>
                    </div>
                    <div>
                        <img alt="" src={process.env.PUBLIC_URL + '/encuentro-fortuna/enc-for-4.jpg'} />
                        <p className="legend">Trabajos: Acero, cimbra, concreto, topografía. Cimentación profunda, 
                        cimentación superficial y estructura.</p>
                    </div>
                  </Carousel>
                </div>
              </Fade>
            </div>
            <h2 className="title-proy">Otros Proyectos: </h2>
            <ul className="lista-proy">
              <li>
                Distribuidor Vial Santa Martha 
                <button className="mat-button-slide" onClick={() => setOpen1(true)}>Ver fotos</button>
                <Modal open={open1} onClose={() => setOpen1(false)}>
                  <div className="carousel-modal">
                    <h2>Distribuidor Vial Santa Martha</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/santa-martha/SAN-MAR-1.png'} />
                          <p className="legend">Supervisión: ICA</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/santa-martha/SAN-MAR-2.png'} />
                          <p className="legend">Personal a cargo: 150 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/santa-martha/SAN-MAR-3.png'} />
                          <p className="legend">Ubicación: Av. Zaragoza, Av. Ermita y Autopista México-Puebla, CDMX-Edo. Mex</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Levantamiento topográfico de la autopista México-Cuernavaca
                <button className="mat-button-slide" onClick={() => setOpen2(true)}>Ver fotos</button>
                <Modal open={open2} onClose={() => setOpen2(false)}>
                  <div className="carousel-modal">
                    <h2>Levantamiento topográfico de la autopista México-Cuernavaca</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/mex-cue/mex-cue-1.JPG'} />
                          <p className="legend">Supervisión: SCT</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/mex-cue/mex-cue-2.JPG'} />
                          <p className="legend">Personal a cargo: 20 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/mex-cue/mex-cue-3.JPG'} />
                          <p className="legend">Ubicación: Autopista México-Cuernavaca</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/mex-cue/mex-cue-4.JPG'} />
                          <p className="legend">Trabajos: Topografía, GPS y elaboración de planos.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Levantamiento batimétrico de 8 km del arroyo “el oro”
                <button className="mat-button-slide" onClick={() => setOpen3(true)}>Ver fotos</button>
                <Modal open={open3} onClose={() => setOpen3(false)}>
                  <div className="carousel-modal">
                    <h2>Levantamiento batimétrico de 8 km del arroyo “el oro”</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/alamo-ver/ala-ver-1.JPG'} />
                          <p className="legend">Supervisión: CONAGUA</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/alamo-ver/ala-ver-2.JPG'} />
                          <p className="legend">Personal a cargo: 25 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/alamo-ver/ala-ver-3.JPG'} />
                          <p className="legend">Ubicación: Alamo, Veracruz</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/alamo-ver/ala-ver-4.JPG'} />
                          <p className="legend">Trabajos: Topografía, batimetría y elaboración de planos.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Levantamiento del antiguo palacio del ayuntamiento 
                <button className="mat-button-slide" onClick={() => setOpen4(true)}>Ver fotos</button>
                <Modal open={open4} onClose={() => setOpen4(false)}>
                  <div className="carousel-modal">
                    <h2>Levantamiento del antiguo palacio del ayuntamiento</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/ant-pal/ant-pal-1.JPG'} />
                          <p className="legend">Supervisión: Gobierno de la Ciudad de México.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/ant-pal/ant-pal-2.JPG'} />
                          <p className="legend">Personal a cargo: 10 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/ant-pal/ant-pal-3.JPG'} />
                          <p className="legend">Ubicación: C. 20 de noviembre y C. 5 de febrero, Centro Historico, CDMX</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/ant-pal/ant-pal-4.JPG'} />
                          <p className="legend">Trabajos: Topografía y elaboración de planos.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Edificio Habitacional: Citta San Jerónimo
                <button className="mat-button-slide" onClick={() => setOpen5(true)}>Ver fotos</button>
                <Modal open={open5} onClose={() => setOpen5(false)}>
                  <div className="carousel-modal">
                    <h2>Edificio Habitacional: Citta San Jerónimo</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/san-jeronimo/san-jer-1.jpg'} />
                          <p className="legend">Supervisión: Gobierno de la Ciudad de México.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/san-jeronimo/san-jer-2.jpg'} />
                          <p className="legend">Personal a cargo: 10 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/san-jeronimo/san-jer-3.jpg'} />
                          <p className="legend">Ubicación: C. 20 de noviembre y C. 5 de febrero, Centro Historico, CDMX</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/san-jeronimo/san-jer-4.jpg'} />
                          <p className="legend">Trabajos: Topografía y elaboración de planos.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Obra Civil en edificio de departamentos Tabasco 154
                <button className="mat-button-slide" onClick={() => setOpen6(true)}>Ver fotos</button>
                <Modal open={open6} onClose={() => setOpen6(false)}>
                  <div className="carousel-modal">
                    <h2>Obra Civil en edificio de departamentos Tabasco 154</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tabasco/tabasco-1.jpg'} />
                          <p className="legend">Supervisión: TEFCO (gerencia) Y MM (Desarrollador).</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tabasco/tabasco-2.jpg'} />
                          <p className="legend">Personal a cargo: 40 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tabasco/tabasco-3.jpg'} />
                          <p className="legend">Ubicación: C. Tabasco, Roma Norte, CDMX</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tabasco/tabasco-4.jpg'} />
                          <p className="legend">Trabajos: Acero, cimbra, concreto gris, concreto beige, albañilerías y topografía. Cimentación superficial y estructura.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Obra Civil en Edificio de departamentos Musset 326
                <button className="mat-button-slide" onClick={() => setOpen7(true)}>Ver fotos</button>
                <Modal open={open7} onClose={() => setOpen7(false)}>
                  <div className="carousel-modal">
                    <h2>Obra Civil en Edificio de departamentos Musset 326</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/musset/musset-1.jpg'} />
                          <p className="legend">Supervisión: TEFCO.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/musset/musset-2.jpg'} />
                          <p className="legend">Personal a cargo: 100 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/musset/musset-3.jpg'} />
                          <p className="legend">Ubicación: C. Musset, Polanco, CDMX</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/musset/musset-4.jpg'} />
                          <p className="legend">Trabajos: Acero, cimbra, concreto gris, topografía, acabados, albañilerías, aparentados y reparación de trabajos mal ejecutados con anterioridad en estructura y acabados. Estructura y acabados.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                Edificio Habitacional Tlacoquemécatl #524
                <button className="mat-button-slide" onClick={() => setOpen8(true)}>Ver fotos</button>
                <Modal open={open8} onClose={() => setOpen8(false)}>
                  <div className="carousel-modal">
                    <h2>Edificio Habitacional Tlacoquemécatl #524</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tlaco/tlaco-1.jpg'} />
                          <p className="legend">Supervisión: Comité de vigilancia del edificio.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tlaco/tlaco-2.jpg'} />
                          <p className="legend">Personal a cargo: 20 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tlaco/tlaco-3.jpg'} />
                          <p className="legend">Ubicación: C. Tlacoquemecatl, Del Valle, CDMX</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/tlaco/tlaco-4.jpg'} />
                          <p className="legend">Trabajos: Pintura, aplanados, yeso, impermeabilizante, demolición y refuerzo de muros.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
              <li>
                CAEM
                <button className="mat-button-slide" onClick={() => setOpen9(true)}>Ver fotos</button>
                <Modal open={open9} onClose={() => setOpen9(false)}>
                  <div className="carousel-modal">
                    <h2>Revisión Estructural y Topografía de Instalaciones de la Comisión de Aguas del Estado de México</h2>
                    <Carousel showArrows={true}>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/caem/caem-1.jpg'} />
                          <p className="legend">Supervisión: Geotem.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/caem/caem-2.jpg'} />
                          <p className="legend">Personal a cargo: 60 trabajadores</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/caem/caem-3.jpg'} />
                          <p className="legend">Ubicación: Instalaciones de la Comisión de Aguas del Estado de México (CAEM), Edo. Mex.</p>
                      </div>
                      <div>
                          <img alt="" src={process.env.PUBLIC_URL + '/caem/caem-4.JPG'} />
                          <p className="legend">Trabajos: Gps, Dron, levantamiento de 77 instalaciones de CAEM y su representación en planos.</p>
                      </div>
                    </Carousel>
                  </div>
                </Modal>
              </li>
            </ul>
          </div>
        </Slide>
        <Slide left cascade duration={2000}>
          <Parallax bgImage="https://i.imgur.com/2NdDZd6.jpg" strength={500}>
            <div style={{ height: 500 }}>
            </div>
          </Parallax>
        </Slide>
        <Slide right duration={2000}>
          <div className="section-column" id="section5">
            <h2 className="titles">
              <Zoom mirror={true} duration={800} delay={500}>Contacto</Zoom>
            </h2>
            <Fade buttom delay={2500}>
              <div className="info-contact">
                <span>Envianos un correo a: </span> 
                <span><a href="mailto:contacto@deigeo.com.mx">contacto@deigeo.com.mx</a></span>
                <span className="direction-of">
                  Podrás encontrarnos en: 
                </span>
                <span>
                  España 505, Edificio 1ª, Apartamento 101, Col. San Nicolás Tolentino, Iztapalapa, 
                  CP: 09850, CDMX.
                </span>
                <span><a href="https://goo.gl/maps/ArX47VmJEZLdg1469">Ver en Google Maps</a></span>
              </div>
            </Fade>
            <div id="contact-us">
              <Fade buttom delay={3000}>
                <div className="contact-person">
                  <img className="rounded-profile" src={process.env.PUBLIC_URL + 'german-profile.jpg'} alt="Germán Alvarez" />
                  <h2>Arq. Germán Álvarez Ávila</h2>
                  <h3>Director General</h3>
                  <div className="phone">
                    <i className="material-icons">phone</i>
                    <span><a href="tel:553-379-5720">553-379-5720</a></span>
                  </div>
                  <div className="phone">
                    <i className="material-icons">email</i>
                    <span><a href="mailto:german.a@deigeo.com.mx">german.a@deigeo.com.mx</a></span>
                  </div>
                </div>
              </Fade>
              <Fade buttom delay={3500}>
                <div className="contact-person">
                  <img className="rounded-profile" src={process.env.PUBLIC_URL + 'erick-profile.jpg'} alt="Erick Magaña" />
                  <h2>Ing. Erick Magaña Balderrama</h2>
                  <h3>Director Operativo</h3>
                  <div className="phone">
                    <i className="material-icons">phone</i>
                    <span><a href="tel:561-167-6305">561-167-6305</a></span>
                  </div>
                  <div className="phone">
                    <i className="material-icons">email</i>
                    <span><a href="mailto:erick.m@deigeo.com.mx">erick.m@deigeo.com.mx</a></span>
                  </div>
                </div>
              </Fade>
              <Fade buttom cascade delay={3700}>
                <div className="contact-form">
                  <h4>¡Mándanos un mensaje!</h4>
                  <div className="form-mess">
                    <div className="group">      
                      <input type="text" required placeholder="Introduce tu nombre" onChange={e => setName(e.target.value)}/>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Nombre</label>
                    </div>
                    <div className="group">      
                      <input type="text" placeholder="Introduce tu correo electrónico" required onChange={e => setEmail(e.target.value)}/>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Correo</label>
                    </div>
                    <div className="group">      
                      <input type="text" placeholder="Introduce tu número telefónico" required onChange={e => setPhone(e.target.value)}/>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Teléfono</label>
                    </div>
                    <div className="group">      
                      <textarea type="text" required placeholder="Escribe tu mensaje" onChange={e => setMessage(e.target.value)}></textarea>
                      <span className="highlight"></span>
                      <span className="bar"></span>
                      <label>Mensaje</label>
                    </div>
                    <button className="mat-button" onClick={send}>Enviar</button>
                  </div>
                </div>
              </Fade>
            </div>
          </div>
        </Slide>
        <div className="footer">
          <h4>Todos los derechos reservados {new Date().getFullYear()} ®.</h4>
          <h5>Diseñado por <a href="mailto:sofware@talentics.mx"> Talentics </a></h5>
        </div>
      </div>
      <Lines customLoading={charge} color="white" background="linear-gradient(to right, #000428, #004192)" />
    </div>
  );
}

export default App;
