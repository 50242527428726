import React, { useState, useEffect } from 'react';
import { AnimateOnChange, HideUntilLoaded } from 'react-animation'

export default function Valores(){
        const words = [
          'Cooperación',
          'Innovación',
          'Seguridad',
          'Amabilidad',
          'Confianza',
          'Atención',
          'Responsabilidad',
          'Motivación',
          'Compromiso',
          'Competitividad',
          'Ética Profesional',
          'Apertura del cambio',
          'Calidez'
        ];
      
        const [current, setCurrent] = useState(0);
      
        useEffect(() => {
          const interval = setInterval(() => {
            if (current === words.length - 1) {
              setCurrent(0)
            } else {
              setCurrent(current + 1)
            }
          }, 2000);
          return (() => {
            clearInterval(interval)
          })
        })
        
        return (
          <div>
            <h1 className="valores-mob"><AnimateOnChange>{words[current]}</AnimateOnChange></h1>
          </div>
        )

}