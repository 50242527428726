import React, {Component} from 'react';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

export default class CarouselMain extends Component{
    constructor(props) {
        super(props);
        this.state = {
            title: ''
        }
    }

    render() {
        return(
            <Carousel
                autoPlay={10000}
                animationSpeed={1000}
                infinite
            >
                <img className="car-cover" src={process.env.PUBLIC_URL + '/main/cover-1.jpg'} />
                <img className="car-cover" src={process.env.PUBLIC_URL + '/cover-2.jpg'} />
                <img className="car-cover" src={process.env.PUBLIC_URL + '/main/cover-2.jpg'} />
                <img className="car-cover" src={process.env.PUBLIC_URL + '/main/cover-3.jpg'} />
            </Carousel>
        )
    }
}